import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { taskMiddleware } from "react-palm/tasks";

import keplerGlReducer from "kepler.gl/reducers";
import { LOCALE_CODES } from "kepler.gl/localization";


import Rastrear from "./components/rastrear";
import Map from "./components/map";

import "./index.css";
import tokenService from "./services/tokenService";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Map />,
        children: [],
    },
    {
        path: "/rastrear",
        element: <Rastrear />,
    },
]);

const customizedKeplerGlReducer = keplerGlReducer.initialState({
    uiState: {
        locale: LOCALE_CODES.pt,
        currentModal: null,
    },
});

const store = configureStore({
    reducer: {
        keplerGl: customizedKeplerGlReducer,
    },
    middleware: [taskMiddleware],
});

tokenService.listenForToken();

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <RouterProvider router={router} />
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);
