class TokenService {
    private token: string | null = null;
    private listeners: Array<(token: string | null) => void> = [];
    
    getToken() {
        return this.token;
    }

    async getTokenAsync(): Promise<string> {
        if (this.token) {
            return this.token;
        }

        return new Promise((resolve) => {
            const unsubscribe = this.subscribe((token) => {
                if (token) {
                    resolve(token);
                    unsubscribe();
                }
            });
        });
    }

    subscribe(listener: (token: string | null) => void) {
        this.listeners.push(listener);
        return () => {
            this.listeners = this.listeners.filter((l) => l !== listener);
        };
    }

    private notifyListeners() {
        this.listeners.forEach((listener) => listener(this.token));
    }

    listenForToken() {
        const handleMessage = (event: MessageEvent) => {
            // Adicione validação da origem se necessário
            if (event.data?.token) {
                this.token = event.data.token;
                this.notifyListeners();
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }
}

const tokenService = new TokenService();
export default tokenService;
